





















































































import { walletStore } from '@/stores/wallet-store'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ConnectWalletDialog extends Vue {
  @PropSync('drawer') drawerDialog!: boolean

  wallet = walletStore

  get hideMetamask() {
    console.log(this.$router.currentRoute.path)
    return true
  }

  get form() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
      case 'md':
        return { height: 320, square: 134, icon: 47 }
      case 'lg':
      case 'xl':
        return { height: 570, square: 226, icon: 80 }
    }
  }

  async connectMyAlgoWallet() {
    await this.wallet.connectMyAlgoWallet()
    this.drawerDialog = false
  }

  async connectViaWalletConnect() {
    await this.wallet.connectPeraWallet()
    this.drawerDialog = false
  }
}
